import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { useMediaQuery } from "../../hooks/useMediaQuery";
import data from "../../assets/fake-data/data-today-pick";
import { useWallet } from "../../contexts/wallet";
import cw721 from "../../contracts/cw721";

const MyNftList = () => {
    const isRowBased = useMediaQuery("(min-width: 300px)");
    const wallet = useWallet();

    const [pieces, setPieces] = useState([0, 0, 0, 0]);

    useEffect(() => {
        const getOwned = async () => {
            let client = cw721(wallet.getClient());
            let res = await client.getTokens(wallet.address);

            let limit = res.length;
            let junoGravity = 0;
            let osmosisPotion = 0;
            let toxicRain = 0;
            let blueMoon = 0;

            for (let i = 0; i < limit; i++) {
                let details = await client.getNftInfo(res[i]);
                if (details) {
                    switch (details.extension.name) {
                        case "BlueMoon":
                            blueMoon++;
                            break;
                        case "ToxicRain":
                            toxicRain++;
                            break;
                        case "OsmosisPotion":
                            osmosisPotion++;
                            break;
                        default:
                            junoGravity++;
                            break;
                    }
                }

                setPieces([junoGravity, osmosisPotion, toxicRain, blueMoon]);
            }
        };

        if (wallet.initialized && cw721) {
            getOwned();
        }
    }, [wallet]);

    return (
        <Fragment>
            <section className="tf-section today-pick">
                <div className="themesflat-container">
                    <div className="row">
                        {data?.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className="col-xl-3 col-lg-4 col-md-6 col-sm-6"
                                >
                                    <div
                                        className={`sc-card-product ${
                                            item.feature ? "comingsoon" : ""
                                        } `}
                                    >
                                        <div className="card-media">
                                            <img
                                                src={item.imggif}
                                                alt="axies"
                                            />
                                        </div>

                                        <div className="card-title">
                                            <h5 className="style2">
                                                <Link to="">{item.title}</Link>
                                            </h5>
                                            <div
                                                className="tags"
                                                style={{
                                                    width: "auto",
                                                    padding: "5px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                {item.tags}
                                            </div>
                                        </div>

                                        <div
                                            className="meta-info"
                                            style={{
                                                display: "flex",
                                                flexDirection: !isRowBased
                                                    ? "column"
                                                    : "row",
                                            }}
                                        >
                                            <div></div>

                                            <div className="price">
                                                <h5>
                                                    {pieces[index] + " Pieces"}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>

            {/* <CardModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                selectedCard={selectedCard}
            /> */}
        </Fragment>
    );
};

MyNftList.propTypes = {
    data: PropTypes.array.isRequired,
};

export default MyNftList;
