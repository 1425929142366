import img1 from '../inspirest/slider/cosmonaut.png';
// import img2 from '../inspirest/slider/cosmonaut.png';

import imgbg1 from '../images/slider/bg_slide_1.png'
// import imgbg2 from '../images/slider/bg_slide_1.png'


const heroSliderData = [
    {
        title_1: "Discover, find,",
        // title_2: "Own extraordinary",
        title_2: "The greatest opportunities with",
        title_3: "Inspirest NFTs",
        // description: "Marketplace for monster character cllections non fungible token NFTs",
        description: "Get privileges with the Inspirest NFT collection that provides regular income",
        img: img1,
        imgbg: imgbg1,
        class:'left'
    },
    // {
    //     title_1: "Discover, find,",
    //     title_2: "Sell extraordinary",
    //     title_3: "Inspirest NFTs",
    //     description: "Marketplace for monster character cllections non fungible token NFTs",
    //     img: img2,
    //     imgbg: imgbg2,
    //     class:'center'
    // },
    // {
    //     title_1: "Discover, find,",
    //     title_2: "Sell extraordinary",
    //     title_3: "Inspirest NFTs",
    //     description: "Marketplace for monster character cllections non fungible token NFTs",
    //     img: img2,
    //     imgbg: imgbg2,
    //     class:'right'
    // },
    
]

export default heroSliderData;