import { Link } from "react-router-dom";
import React, { useState, Fragment, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CardModalSingle from "./CardModalSingle";
import CardModalMultiple from "./CardModalMultiple";

import { useMediaQuery } from "../../hooks/useMediaQuery";
import QuantityImg from "./../../assets/inspirest/quantity.png";

import data from "../../assets/fake-data/data-today-pick";
import HomeNftListMultipleCard from "./HomeNftListMultipleCard";

import { useWallet } from "../../contexts/wallet";
import minter from "../../contracts/minter";

const notify = () =>
    toast("Insufficient JUNO amount!", {
        fontSize: "24px",
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
const notify2 = () =>
    toast("Please connect with your keplr wallet!", {
        fontSize: "24px",
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

const HomeNftList = () => {
    const NUMBER_LEFT = "50%";
    const NUMBER_RIGHT = "61%";
    const wallet = useWallet();

    const [refresh, setRefresh] = useState(false);
    const [availableGravity, setAvailableGravity] = useState(400);
    const [availableRandom, setAvailableRandom] = useState(1200);
    const [junoBalance, setJunoBalance] = useState(0);

    useEffect(() => {
        const loadOwnedCount = async () => {
            let client = minter(wallet.getClient());
            let res = await client.getNftCount();
            if (res) {
                setAvailableGravity(availableGravity - res.first_kind_count);
                let owned =
                    res.fourth_kind_count +
                    res.second_kind_count +
                    res.third_kind_count;
                setAvailableRandom(availableRandom - owned);
            }

            setRefresh(false);
        };

        const loadJunoBalance = async () => {
            let balances = wallet.balance;
            if (balances !== []) {
                balances.forEach((item) => {
                    if (item.denom === "ujuno") {
                        setJunoBalance(Number(item.amount / 1000000));
                    }
                });
            }
        };

        if (wallet.initialized && minter) {
            loadOwnedCount();
            loadJunoBalance();
        }
        // eslint-disable-next-line
    }, [wallet, refresh]);

    const buyJunoGravity = async () => {
        try {
            if (!wallet.initialized || !minter) return notify2();

            if (junoBalance < 30) {
                notify();
            }

            const client = minter(wallet.getClient());
            await client.mint(wallet.address, "30000000");
            //let res = await client.adminMint(wallet.address);
            //console.log(res);
        } catch (e) {
            console.log(e);
        }
    };

    const buyRandom = async () => {
        try {
            if (!wallet.initialized || !minter) return notify2();
            if (junoBalance < 15) {
                notify();
            }

            const client = minter(wallet.getClient());
            await client.mintMultiple(wallet.address, "15000000");
            //let res = await client.adminMintMultiple(wallet.address);
            //console.log(res);
        } catch (e) {}
    };

    const isRowBased = useMediaQuery("(min-width: 300px)");
    const [singleModalShow, setSingleModalShow] = useState(false);
    const [multipleModalShow, setMultipleModalShow] = useState(false);

    return (
        <Fragment>
            <section className="tf-section today-pick">
                <div className="themesflat-container">
                    <div
                        className="row"
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {data?.slice(0, 1)?.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className="col-xl-3 col-lg-4 col-md-6 col-sm-6"
                                >
                                    <div
                                        className={`sc-card-product ${
                                            item.feature ? "comingsoon" : ""
                                        } `}
                                    >
                                        <div
                                            className="card-media"
                                            onClick={() =>
                                                setSingleModalShow(true)
                                            }
                                            style={{ cursor: "pointer" }}
                                        >
                                            <img
                                                src={item.imgpng}
                                                alt="axies"
                                            />

                                            <Link
                                                to=""
                                                className="wishlist-button"
                                            >
                                                <img
                                                    src={QuantityImg}
                                                    alt="Axies"
                                                    style={{
                                                        width: "15px",
                                                        height: "15px",
                                                        marginRight: "5px",
                                                    }}
                                                />
                                                <span className="number-like">
                                                    {NUMBER_LEFT}
                                                </span>
                                            </Link>
                                        </div>

                                        <div className="card-title">
                                            <h5 className="style2">
                                                <Link to="">{item.title}</Link>
                                            </h5>
                                            <div
                                                className="tags"
                                                style={{
                                                    width: "auto",
                                                    padding: "5px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                {item.tags}
                                            </div>
                                        </div>

                                        <div
                                            className="meta-info"
                                            style={{
                                                display: "flex",
                                                flexDirection: !isRowBased
                                                    ? "column"
                                                    : "row",
                                            }}
                                        >
                                            <div>
                                                <button
                                                    className="sc-button fl-button pri-3 no-bg"
                                                    style={{
                                                        whiteSpace: "nowrap",
                                                        padding: "10px",
                                                    }}
                                                    onClick={() =>
                                                        buyJunoGravity()
                                                    }
                                                >
                                                    <span>Buy NFT</span>
                                                </button>

                                                <button
                                                    className="sc-button fl-button pri-3 no-bg"
                                                    onClick={() => {
                                                        setSingleModalShow(
                                                            true
                                                        );
                                                    }}
                                                    style={{
                                                        whiteSpace: "nowrap",
                                                        padding: "10px",
                                                        marginLeft: "10px",
                                                    }}
                                                >
                                                    <span>Details</span>
                                                </button>
                                            </div>

                                            <div className="price">
                                                {/* <span>Current Bid</span> */}
                                                <h5> {item.price}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                        <HomeNftListMultipleCard
                            setMultipleModalShow={setMultipleModalShow}
                            onClick={buyRandom}
                            number={NUMBER_RIGHT}
                        />
                    </div>
                </div>
            </section>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                theme="dark"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                bodyStyle={{
                    fontSize: "16px",
                    fontFamily: "Trebuchet MS",
                    fontWeight: "bold",
                }}
                progressStyle={{ background: "#e250e5" }}
            />
            <CardModalSingle
                show={singleModalShow}
                onHide={() => setSingleModalShow(false)}
                item={data[0]}
            />
            <CardModalMultiple
                show={multipleModalShow}
                onHide={() => setMultipleModalShow(false)}
                item={data?.slice(1, data.length)}
            />
        </Fragment>
    );
};

// HomeNftList.propTypes = {
//     data: PropTypes.array.isRequired,
// }

export default HomeNftList;
