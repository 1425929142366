import React from 'react';

import Slider from './components/slider/Slider';
import Create from './components/Create';
import HomeNftList from './components/HomeNftList';

import heroSliderData from '../assets/fake-data/data-slider';


const Home01 = () => {

    return (
        <div className='home-1'>
            <Slider data={heroSliderData} />
            <HomeNftList/>
            <Create />
        </div>
    );
}

export default Home01;
