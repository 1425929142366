import { useEffect, useState } from "react";
import { SigningCosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import { Decimal } from "@cosmjs/math";
import { keplrConfig } from "../config/keplr";
import { getConfig } from "../config/network";
import { useWallet } from "../contexts/wallet";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const notify = () => toast.error('Keplr extension is not available!', {
    fontSize: '24px',
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
});

export async function createClient(signer, network) {
    const config = getConfig(network);

    return SigningCosmWasmClient.connectWithSigner(config.rpcUrl, signer, {
        gasPrice: {
            amount: Decimal.fromUserInput("0.0025", 100),
            denom: config.feeToken,
        },
    });
}

export async function loadKeplrWallet(config) {
    const anyWindow = window;
    if (!anyWindow.getOfflineSigner) {
        notify();
        throw new Error("Keplr extension is not available");

    }

    await anyWindow.keplr.experimentalSuggestChain(keplrConfig(config));
    await anyWindow.keplr.enable(config.chainId);

    const signer = await anyWindow.getOfflineSignerAuto(config.chainId);
    signer.signAmino = signer.signAmino ?? signer.sign;

    return Promise.resolve(signer);
}

export function useKeplr() {
    const { clear, init, initialized, network } = useWallet();
    const [initializing, setInitializing] = useState(false);
    const config = getConfig(network);

    const disconnect = () => {
        localStorage.clear();
        clear();
    };

    const connect = (walletChange = false) => {
        setInitializing(true);
        loadKeplrWallet(config)
            .then((signer) => {
                init(signer);
                if (walletChange) setInitializing(false);
            })
            .catch((err) => {
                setInitializing(false);
                console.error(err.message);
            });
    };

    useEffect(() => {
        if (!initialized) return;

        setInitializing(false);
    }, [initialized]);

    return {
        connect,
        disconnect,
        initializing,
    };
}

const Keplr = () => {

    return (
        <div>
            <ToastContainer />
        </div>
    )
}

export default Keplr;