import "./App.css";
import React, { useState, useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import Home01 from "./pages/Home01";
import ItemDetails01 from "./pages/ItemDetails01";
import ScrollToTop from "./ScrollToTop";

import Header from "./pages/components/header/Header";
import Footer from "./pages/components/footer/Footer";

import { WalletProvider } from "./contexts/wallet";
import { useKeplr } from "./services/keplr";

const SideEffects = () => {
    const keplr = useKeplr();

    useEffect(() => {
        const walletAddress = localStorage.getItem("wallet_address");
        if (walletAddress) {
            keplr.connect();
        }
    }, [keplr]);

    return null;
};

function App() {
    const [network, setNetwork] = useState("");

    return (
        <WalletProvider network={network} setNetwork={setNetwork}>
            <SideEffects />

            <BrowserRouter basename="/nft">
                <ScrollToTop />
                <Header />
                <Routes>
                    <Route path="" element={<Home01 />} />
                    <Route path="my-nft" element={<ItemDetails01 />} />
                </Routes>
                <Footer />
            </BrowserRouter>
        </WalletProvider>
    );
}

export default App;
