import imgpng1 from '../inspirest/inspirest-nft/Inspirest_Juno_Gravity.png'
import imggif1 from '../inspirest/inspirest-nft/Inspirest_Juno_Gravity.gif'

import imgpng2 from '../inspirest/inspirest-nft/Inspirest_Osmosis_Potion.png'
import imggif2 from '../inspirest/inspirest-nft/Inspirest_Osmosis_Potion.gif'

import imgpng3 from '../inspirest/inspirest-nft/Inspirest_Toxic_Rain.png'
import imggif3 from '../inspirest/inspirest-nft/Inspirest_Toxic_Rain.gif'

import imgpng4 from '../inspirest/inspirest-nft/Inspirest_Blue_Moon.png'
import imggif4 from '../inspirest/inspirest-nft/Inspirest_Blue_Moon.gif'


// import imgCollection1 from '../images/avatar/avt-18.jpg'


const todayPickData = [

    // Juno Gravity
    {
        id: '1',
        imgpng: imgpng1,
        imggif: imggif1,
        title: "Juno Gravity",
        description: `
        Juno's incredible gravitational pull goes beyond orbital 
        limits to reach Inspirest. Juno Gravity brings together 
        Juno's powerful gravity and Inspirest fashion ecosystem. 
        Now is the time to find out Inspirest's unique opportunities 
        with the powerful Juno Gravity. With Juno Gravity NFT, 
        you can generate regular passive income within the Inspirest 
        ecosystem in proportion to company profitability. Get 
        ready to be a part of Inspirest and feel the gravity of Juno.
        `,
        utility: "1% of company profitability will be shared regularly with Juno Gravity owners. (Passive Income)",

        tags: "COSMOS",
        imgAuthor: "https://media-exp1.licdn.com/dms/image/C4D03AQEtjGdCYzY2Pw/profile-displayphoto-shrink_200_200/0/1623667019561?e=1668038400&v=beta&t=a4otIZ7nAaEfGsKMGc2aMUQDudkwdgsC_HKfaNcsUEc", //
        nameAuthor: "Selçuk Madra",
        price: "30 JUNO",
        priceChange: "~$120",
        quantity: "200",
        // feature: "coming soon",
        // imgCollection: imgCollection1,
        nameCollection: "Creative Art 3D",
    },


    // Osmosis Potion
    {
        id: '2',
        imgpng: imgpng2,
        imggif: imggif2,
        // title: "Osmosis Light",
        title: "Osmosis Potion",
        description: `
        The magic potions of Osmosis are now prepared to 
        rediscover the magical world of Inspirest. Inspirest 
        creates NFT designs of clothes that push the limits 
        of imagination with fashion brands. Users who own the 
        Osmosis Potion NFT will receive regular income from 
        the NFT sales of the brands. Let's create the magic 
        potions of fashion together with Osmosis Potion.
        `,
        utility: "1% of the profit on NFT sales will be shared with Osmosis Potion holders on a regular basis. (Passive Income)",

        tags: "COSMOS",
        imgAuthor: "https://media-exp1.licdn.com/dms/image/C4D03AQEtjGdCYzY2Pw/profile-displayphoto-shrink_200_200/0/1623667019561?e=1668038400&v=beta&t=a4otIZ7nAaEfGsKMGc2aMUQDudkwdgsC_HKfaNcsUEc", //
        nameAuthor: "Selçuk Madra",
        price: "15 JUNO",
        priceChange: "~$96",
        quantity: "400",
        // imgCollection: imgCollection1,
        nameCollection: "Creative Art 3D"
    },

    // Toxic Rain
    {
        id: '3',
        imgpng: imgpng3,
        imggif: imggif3,
        title: "Toxic Rain",
        // title: "Meteor Shower",
        description: `
        Are you ready to feel the intensity of Inspirest toxic 
        rains? You seem to be hearing the powerful sound of 
        the rain already. With the power of Toxic Rain NFT, 
        you will enjoy the advantages of being a priority in 
        the Inspirest universe. Users with Toxic Rain always 
        get priority in participating in Inspirest airdrops. 
        Rule the airdrops with the Toxic Rain NFT.
        `,
        utility: "Toxic Rain NFT holders will always get priority in participating in the airdrops. (Passive Income)",

        tags: "COSMOS",
        imgAuthor: "https://media-exp1.licdn.com/dms/image/C4D03AQEtjGdCYzY2Pw/profile-displayphoto-shrink_200_200/0/1623667019561?e=1668038400&v=beta&t=a4otIZ7nAaEfGsKMGc2aMUQDudkwdgsC_HKfaNcsUEc", //
        nameAuthor: "Selçuk Madra",
        price: "15 JUNO",
        priceChange: "~$60",
        quantity: "400",
        // imgCollection: imgCollection1,
        nameCollection: "Creative Art 3D"
    },

    // blue moon
    {
        id: '4',
        imgpng: imgpng4,
        imggif: imggif4,
        title: "Blue Moon",
        description: `
        Get ready to meet the strongest state of the moon. 
        A constant force that will guide you with its blue 
        glow even in the most difficult moments. A source of 
        luck that will open the door to opportunities within 
        Inspirest. With the Blue Moon NFT, you can earn more 
        from the reward distributions in Inspirest. Continuously 
        multiply your rewards with the Blue Moon.
        `,
        utility: "Blue Moon NFT holders will receive x20% more revenue in reward distribution. (Passive Income)",


        tags: "COSMOS",
        imgAuthor: "https://media-exp1.licdn.com/dms/image/C4D03AQEtjGdCYzY2Pw/profile-displayphoto-shrink_200_200/0/1623667019561?e=1668038400&v=beta&t=a4otIZ7nAaEfGsKMGc2aMUQDudkwdgsC_HKfaNcsUEc", //
        nameAuthor: "Selçuk Madra",
        price: "15 JUNO",
        priceChange: "~$60",
        quantity: "400",
        // imgCollection: imgCollection1,
        nameCollection: "Creative Art 3D"
    },

]

export default todayPickData;