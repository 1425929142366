import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useMediaQuery } from "../../hooks/useMediaQuery";
import dataStore from "../../assets/fake-data/data-today-pick";
import QuantityImg from "./../../assets/inspirest/quantity.png";

const HomeNftListMultipleCard = ({ setMultipleModalShow, onClick, number }) => {
    const isRowBased = useMediaQuery("(min-width: 300px)");

    const data = dataStore?.slice(1, dataStore.length);

    const [sliderIndex, setSliderIndex] = useState(0);

    // console.log(data[sliderIndex]?)
    // useEffect(() => {
    //     setData(dataStore?.slice(1, dataStore.length))
    // }, [])

    useEffect(() => {
        const sliderInterval = setInterval(() => {
            // setdataSlider(data[Math.floor(Math.random() * data.length)]) // eğer rastgele seçersen aynı sayı gelince duraklamış gibi görünür
            // console.log(Math.floor(Math.random() * data.length))

            if (sliderIndex < 2) {
                setSliderIndex(sliderIndex + 1);
            } else {
                setSliderIndex(0);
            }
        }, 1000);

        return () => {
            clearInterval(sliderInterval);
        };
    }, [data]);

    return (
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div
                className={`sc-card-product ${
                    data[sliderIndex]?.feature ? "comingsoon" : ""
                } `}
            >
                <div
                    className="card-media"
                    onClick={() => setMultipleModalShow(true)}
                    style={{ cursor: "pointer" }}
                >
                    <img src={data[sliderIndex]?.imgpng} alt="axies" />

                    <Link to="" className="wishlist-button">
                        <img
                            src={QuantityImg}
                            alt="Axies"
                            style={{
                                width: "15px",
                                height: "15px",
                                marginRight: "5px",
                            }}
                        />
                        <span className="number-like">{number}</span>
                    </Link>
                </div>

                <div className="card-title">
                    <h5 className="style2">
                        <Link to="">{data[sliderIndex]?.title}</Link>
                    </h5>
                    <div
                        className="tags"
                        style={{
                            width: "auto",
                            padding: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {data[sliderIndex]?.tags}
                    </div>
                </div>

                <div
                    className="meta-info"
                    style={{
                        display: "flex",
                        flexDirection: !isRowBased ? "column" : "row",
                    }}
                >
                    <div>
                        <button
                            className="sc-button fl-button pri-3 no-bg"
                            style={{
                                whiteSpace: "nowrap",
                                padding: "10px",
                            }}
                            onClick={onClick}
                        >
                            <span>Buy NFT</span>
                        </button>

                        <button
                            className="sc-button fl-button pri-3 no-bg"
                            onClick={() => {
                                setMultipleModalShow(true);
                            }}
                            style={{
                                whiteSpace: "nowrap",
                                padding: "10px",
                                marginLeft: "10px",
                            }}
                        >
                            <span>Details</span>
                        </button>
                    </div>

                    <div className="price">
                        {/* <span>Current Bid</span> */}
                        <h5> {data[sliderIndex]?.price}</h5>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeNftListMultipleCard;
