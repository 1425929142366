import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
// import imgsun from '../../assets/images/icon/sun.png'

import imgmoon from '../../../assets/inspirest/moon-2.png'
import imgsun from '../../../assets/inspirest/sun.png'

const DarkMode = () => {
    let clickedClass = "clicked"
    const body = document.body
    const lightTheme = "light"
    const darkTheme = "is_dark"
    let theme

    if (localStorage) {
        theme = localStorage.getItem("theme")
    }

    if (theme === lightTheme || theme === darkTheme) {
        body.classList.add(theme)
    } else {
        body.classList.add(darkTheme)
    }
    // console.log("theme", theme)

    const switchTheme = e => {
        // if (!isDark) {
        if (theme !== "light") {
            body.classList.replace(darkTheme, lightTheme)
            e.target.classList.remove(clickedClass)
            localStorage.setItem("theme", "light")
            theme = lightTheme
            handleOnClick()

        } else {
            body.classList.replace(lightTheme, darkTheme)
            e.target.classList.add(clickedClass)
            localStorage.setItem("theme", "is_dark")
            theme = darkTheme

            handleOnClick() 
        }
        // console.log("switchTheme")
    }
    
    const localIsdark = localStorage.getItem("localIsdark") ? JSON.parse(localStorage.getItem("localIsdark")) : (theme === "light" ? true : false)
    const [isDark, setIsDark] = useState(localIsdark)
    // console.log(isDark);
    useEffect(() => {
        setIsDark(localIsdark)
    }, [localIsdark]);
    const handleOnClick = (e) => {
        setIsDark(!isDark)
        localStorage.getItem("localIsdark", JSON.stringify(!isDark))
    }

    return (
        <div className="mode_switcher">
        {/* <h6>Dark mode <strong>Available</strong></h6> */}
        <Link to="#" 
            onClick={e => switchTheme(e)}  >
            <img src={isDark ? imgmoon : imgsun} alt="" width="30px"/>
        </Link>

    </div>
    );
}

export default DarkMode;
