import React from 'react';
import { Link } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';

import MyNftList from './components/MyNftList';


const ItemDetails01 = () => {

    return (
        <div className='item-details'>

            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">My NFTs</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>My NFTs</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <MyNftList />

        </div>
    );
}

export default ItemDetails01;
