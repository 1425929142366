import React from 'react';
import { Modal } from "react-bootstrap";
import { useMediaQuery } from '../../hooks/useMediaQuery';



const CardModalMultiple = ({ show, onHide, item }) => {

    const isRowBased = useMediaQuery('(min-width: 1000px)');

    return (

        <Modal
            show={show}
            onHide={onHide}
            size={isRowBased ? 'lg' : "lg"}
        >
            <Modal.Header closeButton></Modal.Header>

            {/* <div 
                style={{
                    backgroundColor: 'rgba(34, 34, 34, 0.3)',
                    width: '160px',
                    height: '160px',
                    borderRadius:'50%',
                    boxShadow: '0px 0px 10px 10px purple',
                    position: 'absolute',
                    top: '50px',
                    left: '30px'

                }}
            /> */}

            <div
                style={{
                    display: 'flex',
                    flexDirection: isRowBased ? 'row' : 'column',
                    gap: '10px',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    height: "82vh",
                    margin: '10px',
                    position: 'relative',
                }}
            >

                {/* <div
                    style={{
                        backgroundImage: `url(${item?.img})`,
                        backgroundSize: 'cover',
                        width: '90%',
                        minWidth: '200px',

                        // height: '100%',
                        height: "500px",
                        // position: 'absolute',
                        borderRadius: '10px',
                    }}
                /> */}


                {
                    item?.map((item, index) => {


                        return (
                            <div
                                key={index}
                                style={{
                                    minWidth: '200px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    backgroundColor: 'rgba(34, 34, 34, 0.3)',
                                    backdropFilter: 'blur(8px)',
                                    borderRadius: '10px',
                                    // position: 'relative',
                                }}
                                className="modal-body space-y-20"
                            >


                                <h3>{item?.title}</h3>
                                <img
                                    src={item?.imgpng}
                                    alt="Place a Bid"
                                    style={{
                                        maxWidth: '200px',
                                        borderRadius: '10px',
                                    }}
                                />
                                <p
                                    style={{
                                        fontSize: '14px',
                                        textAlign: 'justify',
                                        textIndent: '2em',
                                    }}
                                >
                                    {item?.description}
                                </p>

                                <p
                                    style={{
                                        fontSize: '12px',
                                        lineHeight: '16px',
                                        color: '#ddd',
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: '13px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        ** Utility : &nbsp;
                                    </span>

                                    {item?.utility}
                                </p>

                            </div>
                        )
                    })
                }

            </div>

        </Modal>

    );
};

export default React.memo(CardModalMultiple);
