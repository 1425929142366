import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import DarkMode from "./DarkMode";

import { useWallet } from "../../../contexts/wallet";
import { useKeplr } from "../../../services/keplr";

import logoheader from "../../../assets/images/logo/logo_dark.png";
import logodark from "../../../assets/images/logo/logo_dark.png";
import logoheader2x from "../../../assets/images/logo/logo_dark@2x.png";
import logodark2x from "../../../assets/images/logo/logo_dark@2x.png";
import { useMediaQuery } from "../../../hooks/useMediaQuery";

const Header = () => {
    const wallet = useWallet();
    const keplr = useKeplr();

    const isRowBased = useMediaQuery("(min-width: 325px)");
    const isShowLogo = useMediaQuery("(min-width: 460px)");
    const myImageStyle = isRowBased ? {} : { height: "100%" };

    const headerRef = useRef(null);
    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    });

    const isSticky = (e) => {
        const header = document.querySelector(".js-header");
        const scrollTop = window.scrollY;
        scrollTop >= 300
            ? header.classList.add("is-fixed")
            : header.classList.remove("is-fixed");
        scrollTop >= 400
            ? header.classList.add("is-small")
            : header.classList.remove("is-small");
    };

    const menuLeft = useRef(null);
    const btnToggle = useRef(null);
    const btnSearch = useRef(null);

    const menuToggle = () => {
        menuLeft.current.classList.toggle("active");
        btnToggle.current.classList.toggle("active");
    };

    const searchBtn = () => {
        btnSearch.current.classList.toggle("active");
    };

    return (
        <header id="header_main" className="header_1 js-header" ref={headerRef}>
            <div className="themesflat-container">
                <div className="row">
                    <div className="col-md-12">
                        <div id="site-header-inner">
                            <div
                                className="wrap-box flex"
                                style={{
                                    padding: "0px !important",
                                }}
                            >
                                {isShowLogo && (
                                    <div id="site-logo" className="clearfix">
                                        <div id="site-logo-inner">
                                            <Link
                                                to="/"
                                                rel="home"
                                                className="main-logo"
                                            >
                                                <img
                                                    className="logo-dark"
                                                    id="logo_header"
                                                    src={logodark}
                                                    srcSet={`${logodark2x}`}
                                                    alt="nft-gaming"
                                                    style={{
                                                        width: !isRowBased
                                                            ? "150px"
                                                            : "200px",
                                                        ...myImageStyle,
                                                    }}
                                                />
                                                <img
                                                    className="logo-light"
                                                    id="logo_header"
                                                    src={logoheader}
                                                    srcSet={`${logoheader2x}`}
                                                    alt="nft-gaming"
                                                    style={{
                                                        width: !isRowBased
                                                            ? "150px"
                                                            : "200px",
                                                        ...myImageStyle,
                                                    }}
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                )}

                                <nav
                                    id="main-nav"
                                    className="main-nav"
                                    ref={menuLeft}
                                ></nav>

                                <div className="flat-search-btn flex">
                                    <div>
                                        <Link
                                            to="/my-nft"
                                            className="sc-button  style-1"
                                            style={{
                                                // position: "absolute",
                                                // top: '18px',
                                                // right: "-40px",
                                                padding: "10px",
                                                marginRight: "10px",
                                            }}
                                        >
                                            <span>My NFTs</span>
                                        </Link>
                                    </div>

                                    <div
                                        className="sc-btn-top"
                                        id="site-header"
                                        onClick={
                                            !wallet.initialized
                                                ? () => keplr.connect()
                                                : () => keplr.disconnect()
                                        }
                                    >
                                        <Link
                                            to="/"
                                            className="sc-button header-slider style style-1 wallet fl-button pri-1"
                                        >
                                            <span>
                                                {wallet.initialized
                                                    ? wallet.name
                                                    : "Wallet connect"}
                                            </span>
                                        </Link>
                                    </div>

                                    <DarkMode />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
