import { coin } from "@cosmjs/proto-signing";

const minter = (client) => {
    const contractAddress =
        "juno13fys2pc2revsnh55q2g5g6y6p9ppan2waww8qhvu3t3s2ju9yjxsw59sa2";

    //QUERY
    const getState = async () => {
        const res = await client.queryContractSmart(contractAddress, {
            get_state: {},
        });

        return res;
    };

    const getNftCount = async () => {
        const res = await client.queryContractSmart(contractAddress, {
            get_n_f_t_count: {},
        });
        return res;
    };

    /// EXECUTE

    const editState = async (senderAddress) => {
        const msg = {
            edit_state: {
                base_price: "50000",
                base_price_multiple: "50000",
                size_limit: 400,
                multisig: "",
                nft_contract: "",
            },
        };
        const res = await client.execute(
            senderAddress,
            contractAddress,
            msg,
            "auto"
        );
        return res.transactionHash;
    };

    const mint = async (senderAddress, price) => {
        const msg = {
            mint: {},
        };

        const res = await client.execute(
            senderAddress,
            contractAddress,
            msg,
            "auto",
            "",
            [coin(price.toString(), "ujuno")]
        );

        return res.transactionHash;
    };

    const adminMint = async (senderAddress, price) => {
        const msg = {
            admin: {},
        };

        const res = await client.execute(
            senderAddress,
            contractAddress,
            msg,
            "auto",
            ""
        );

        return res.transactionHash;
    };

    const adminMintMultiple = async (senderAddress, price) => {
        const msg = {
            admin_multiple: {},
        };

        const res = await client.execute(
            senderAddress,
            contractAddress,
            msg,
            "auto",
            ""
        );

        return res.transactionHash;
    };

    const mintMultiple = async (senderAddress, price) => {
        const msg = {
            mint_multiple: {},
        };

        const res = await client.execute(
            senderAddress,
            contractAddress,
            msg,
            "auto",
            "",
            [coin(price.toString(), "ujuno")]
        );

        return res.transactionHash;
    };

    return {
        getState,
        editState,
        adminMint,
        adminMintMultiple,
        mint,
        mintMultiple,
        getNftCount,
    };
};

export default minter;
