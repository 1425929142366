const cw721 = (client) => {
    const contractAddress =
        "juno1gy0c4puwjj09zk9a57qwzl6q3dp5ymehtx3te86p9vxg04sexmysl9cxk4";
    //QUERY
    const getNumTokens = async () => {
        const res = await client.queryContractSmart(contractAddress, {
            NumTokens: {},
        });
        return res;
    };

    const getNftInfo = async (token_id) => {
        const res = await client.queryContractSmart(contractAddress, {
            NftInfo: { token_id },
        });
        return res;
    };

    const getTokens = async (owner, start_after) => {
        let res = await client.queryContractSmart(contractAddress, {
            Tokens: { owner, start_after, limit: 1000 },
        });
        return res.tokens;
    };

    const getCollectionInfo = async () => {
        const res = await client.queryContractSmart(contractAddress, {
            CollectionInfo: {},
        });
        return res;
    };

    /// EXECUTE

    const transferNft = async (senderAddress, recipient, token_id) => {
        const res = await client.execute(
            senderAddress,
            contractAddress,
            { transfer_nft: { recipient, token_id } },
            "auto"
        );
        return res.transactionHash;
    };

    const burn = async (senderAddress, token_id) => {
        const res = await client.execute(
            senderAddress,
            contractAddress,
            { burn: { token_id: token_id + "" } },
            "auto"
        );
        return res.transactionHash;
    };

    return {
        getCollectionInfo,
        getNumTokens,
        getTokens,
        getNftInfo,
        transferNft,
        burn,
    };
};

export default cw721;
