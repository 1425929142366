import React from 'react';
import { Modal } from "react-bootstrap";
import { useMediaQuery } from '../../hooks/useMediaQuery';



const CardModalSingle = ({ show, onHide, item }) => {

    const isRowBased = useMediaQuery('(min-width: 1000px)');


    return (

        <Modal
            show={show}
            onHide={onHide}
            // size={isRowBased ? 'lg' : "lg"}
            // dialogClassName="modal-90w"
            centered
        >

            <Modal.Header closeButton></Modal.Header>

            <div
                className="modal-body space-y-20 pd-40"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >

                <h3>{item?.title}</h3>

                <img
                    src={item?.imgpng}
                    alt="Place a Bid"
                    style={{
                        maxWidth: '200px',
                        borderRadius: '10px',
                    }}
                />

                <p
                    style={{
                        fontSize: '14px',
                        textAlign: 'justify',
                        textIndent: '2em',
                    }}
                >
                    {item?.description}
                </p>

                <p
                    style={{
                        fontSize: '12px',
                        lineHeight: '16px',
                        color: '#ddd',
                    }}
                >
                    <span
                        style={{
                            fontSize: '13px',
                            fontWeight: 'bold',
                        }}
                    >
                        ** Utility : &nbsp;
                    </span>

                    {item?.utility}
                </p>

            </div>

        </Modal>

    );
};

export default React.memo(CardModalSingle);
